require("./scss/style.scss");
require("./scss/home.scss");

$(window).on("load",function(){$("#status").fadeOut(),$("#preloader").delay(0).fadeOut("slow"),$("body").delay(0).css({overflow:"visible"})}),
$(document).ready(function(){$(window).scrollTop($(window).scrollTop()+1);var o=$("#nav-top").outerHeight();$(window).scroll(function(){$(this).scrollTop()>o?($("#header").addClass("header-fixed"),$(".navbar").css({"background-color":"rgba(255,255,255,1)","box-shadow":"0px 2px 5px rgba(0,0,0,0.1)"}),$(".dropdown-menu").css({"background-color":"rgba(255,255,255,1)"})):($("#header").removeClass("header-fixed"),$(".navbar").css("background-color","rgba(255,255,255,1)"),$(".dropdown-menu").css("background-color","rgba(255,255,255,1)"))}),$(window).scroll(function(){$(this).scrollTop()>o+50?$(".header-fixed #nav-agendamento2").css({visibility:"visible",opacity:"1"}):$(".header-fixed #nav-agendamento2").css({visibility:"hidden",opacity:"0"})}),$(window).scroll(function(){$(this).scrollTop()>350?$(".scroll_top").fadeIn():$(".scroll_top").fadeOut()}),$(".scroll_top").click(function(){return $("html, body").animate({scrollTop:0},1500),!1}),$("#search-hideshow").on("click",function(){$("#search").toggleClass("active")}),
$("button#accept-cookies").click(function(o){o.preventDefault();var e=new Date;e.setTime(e.getTime()+94776479999.99998),
$("#termo").hide(),$.cookie("accept","true",{path:"/",expires:e})})}),
$(window).on("load",function(){$("#status").fadeOut(),$("#preloader").delay(350).fadeOut("slow"),$("body").delay(350).css({overflow:"visible"})}),$("a.anchor").not('[href="#"]').not('[href="#0"]').click(function(o){if(location.pathname.replace(/^\//,"")==this.pathname.replace(/^\//,"")&&location.hostname==this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(console.log(e),o.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-77},1500,function(){var o=$(e);if(o.focus(),o.is(":focus"))return!1;o.attr("tabindex","-1"),o.focus()}))}});var target=(target=window.location.hash).replace("#","");window.location.hash="",$(window).on("load",function(){target&&$("html, body").animate({scrollTop:$("#"+target).offset().top-77},1500,"swing",function(){})});

$('ul li a[href^="#"]').on('click', function(e) {
	e.preventDefault();
	var id = $(this).attr('href'),
			targetOffset = $(id).offset().top;
			
	$('html, body').animate({ 
		scrollTop: targetOffset - 170
	}, 500);
});

require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("slick-carousel");

$(document).ready(function(){
    $('.slider').slick({
      arros: false,
      dots: true,
      autoplay: true,
      slidesToShow:  2,
      infinite: true,
      slidesToScroll: 2,
      autoplaySpeed: 3000,
      speed: 3000,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  });
